.screen-role-permission {
  .lst-role {

    .item-role {
      height: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 0px 12px;
      cursor: pointer;
      border-radius: 6px;
    }

    .active {
      background: #ff0000;
      color: #fff;
    }

    .inactive {
      background: #fff;
      color: unset;
    }
  }
}