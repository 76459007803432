.ant-modal-content {
  .ant-modal-confirm-body-wrapper {
    .ant-modal-confirm-body {
      .ant-modal-confirm-title {
        text-align: center;
      }

      .ant-modal-confirm-content {
        text-align: center;
      }
    }

    .ant-modal-confirm-btns {
      text-align: center;
    }
  }
}