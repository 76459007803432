.min-with-index {
  min-width: 50px;
}

.min-with-status {
  min-width: 100px;
}

.min-with-action {
  min-width: 50px;
}

.w-100-percent {
  width: 100%;
}