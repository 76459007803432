.app-header {

  .collapsed-logo {
    .logo {
      img {
        height: 64px;
        object-fit: contain;
      }
    }
  }

  .flex-header {
    width: 100%;

    .icon-collapsed:hover {
      color: #fff;
    }

    .choose-format-date-time .ant-select-selector {
      width: 220px;
      color: red;
    }
  }

  .user-info {
    padding-right: 24px;
  }

}

.app-header-menu {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
}

.drawer-menu .ant-drawer-body {
  padding: 0;
}

.drawer-menu .ant-drawer-header {

  background: #000c17;
  padding: 12px 0px;

  .logo {
    text-align: center;

    img {
      height: 64px;
      object-fit: contain;
    }
  }
}