.ant-layout-sider {
  overflow: auto;
  height: 100vh;

  .logo {
    text-align: center;

    img {
      height: 64px;
      object-fit: contain;
    }
  }
}

.ant-layout-header {
  padding: 0;
  background: #001529;
  display: flex;
  justify-content: space-between;

  .ant-btn {
    font-size: 16px;
    width: 64px;
    height: 64px;
    object-fit: contain;
    color: #fff;
  }

  .lang-group {
    display: flex;
    align-items: center;
    flex-direction: row;

    strong {
      margin: 0px 5px;
      color: #fff;
    }
  }
}

.ant-layout-content {
  padding: 12px;
  overflow: initial;
  background: #e2e3e6;
  min-height: calc(100vh - 64px);
}


.ant-input-filled {
  background: rgb(10 134 23 / 20%);
}

.ant-select-filled {
  background: rgb(10 134 23 / 20%);
  border-radius: 6px;
}

.ant-input-number-filled {
  background: rgb(10 134 23 / 20%);
}

.ant-avatar {
  border: 1px solid #fff;
}
