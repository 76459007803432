.ant-spin {
  position: fixed;
  z-index: 999999;

  .ant-spin-dot-item {
    background: #f60;
  }
}

.ant-spin-nested-loading>div>.ant-spin {
  z-index: 9999;
  max-height: 100vh !important;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
}